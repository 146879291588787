import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import PatternIframe from "../component/PatternIframe.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="https://www.figma.com/design/bBkL8XO5q7GyKad3fJaF5F/Pattern-User-Management?node-id=1-3&t=L9CVtUY6ekbfWgqm-1" codeUrl="https://gitlab.playcourt.id/telkomdev/legion-pattern-dashboard/-/tree/phoenix" mdxType="ChecklistResourcesSection" />
    <p>{`The use case of user management contains a collection of user management processes that are commonly found and collected from many sites. The use cases will provide examples of creating a granular model of user management that matches how many users would be in the company.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Guideline pattern`}</strong>{`: In this pattern, there will be various types of user management patterns based on the granularity scope. Please be aware on every page, there are Pros & Cons that we should read carefully.`}</p>
    </InlineNotification>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Case: Access Control List (ACL)</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case: Role Based Access Control (RBAC)</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <InlineNotification kind='warning' mdxType="InlineNotification">
      <p><strong parentName="p">{`Customize pattern:`}</strong>{` In this pattern, various use cases of user management are presented based on each process. We will add the use cases periodically.`}</p>
    </InlineNotification>
    <h2>{`Case: Access Control List (ACL)`}</h2>
    <PatternIframe url="https://legion-phoenix-pattern-dashboard.vercel.app/user-management/list" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User Story`}</strong>{`: As an admin, I want to monitor and manage the permissions of users directly as I created the users so that I can save some time.`}</p>
    <p>{`Requirement Scenario/Acceptance Criteria`}</p>
    <ul>
      <li parentName="ul">{`Provide specific control list of resources that can be accessed by users`}</li>
      <li parentName="ul">{`Provide control access to the permissions list of resources`}</li>
      <li parentName="ul">{`Able to create users directly within its permission control`}</li>
      <li parentName="ul">{`Provide edit and delete users to admin`}</li>
      <li parentName="ul">{`Able to manage the permissions of the users list`}</li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" text="This pattern is a suitable choice for environments where there is a high chance of variation in access requirements." aspectRatio="2:1" colLg="6" mdxType="DoDont" />
  <DoDont type="dont" text="This pattern is not suitable for managing complex resources and many types of users." aspectRatio="2:1" colLg="6" mdxType="DoDont" />
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case: Role Based Access Control (RBAC)`}</h2>
    <PatternIframe url="https://legion-phoenix-pattern-dashboard.vercel.app/user-management/role" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User story`}</strong>{`: As an admin, I want to monitor and manage permissions of roles for users in my company so that I can better assign users to each role.`}</p>
    <p>{`Requirement Scenario/Acceptance Criteria`}</p>
    <ul>
      <li parentName="ul">{`Separate the list of roles and users in different tabs or pages`}</li>
      <li parentName="ul">{`Provide ability to create roles with specific permissions (e.g. view, update, hide, and more)`}</li>
      <li parentName="ul">{`Provide ability to assign users to specific roles when it’s created`}</li>
      <li parentName="ul">{`Provide information of how many users were assigned to their role`}</li>
      <li parentName="ul">{`Provide ability to edit and delete the roles and users`}</li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" text="This pattern is suitable for environments where the number of users and features grow while only managing it by the roles & permissions" aspectRatio="2:1" colLg="6" mdxType="DoDont" />
  <DoDont type="dont" text="This pattern is not suitable for resources that require specific permissions." aspectRatio="2:1" colLg="6" mdxType="DoDont" />
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      